var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"video-container"},[(_vm.videoUrl && !_vm.quillVideo)?_c('video',{staticStyle:{"width":"450px","height":"300px"},attrs:{"src":_vm.videoUrl,"controls":"controls"}},[_vm._v(" 您的浏览器不支持视频播放 ")]):_vm._e(),(_vm.videoUrl && !_vm.quillVideo)?_c('el-button',{staticClass:"delete",attrs:{"type":"danger","size":"mini","icon":"el-icon-delete","circle":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemove()}}}):_vm._e()],1),(!_vm.videoUrl || _vm.quillVideo)?_c('el-upload',{ref:"upload",attrs:{"action":_vm.actionVideo,"headers":_vm.headers,"data":_vm.params,"list-type":"picture","disabled":_vm.readOnly,"multiple":false,"limit":1,"accept":"video/*","http-request":_vm.httpRequest,"show-file-list":false,"on-change":_vm.onChange,"before-upload":function(){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.uploadBeforeOne.apply(this,argsArray.concat( ['files']))},"on-success":function(){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
_vm.uploadSuccessOne.apply(this,argsArray.concat( ['files']))},"on-error":function(){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
_vm.uploadError.apply(this,argsArray.concat( ['files']))}}},[[(_vm.styleType === 'button')?_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.quillVideo),expression:"!quillVideo"}],attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]):(_vm.styleType === 'add')?_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"}):_vm._e(),(_vm.showHintText)?_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("只能上传mp4文件，文件大小限制在"+_vm._s(_vm.filesSize)+_vm._s(_vm.filesSizeUnit)+"以内。 ")]):_vm._e()]],2):_vm._e(),(_vm.isShowprogress)?_c('el-progress',{staticStyle:{"width":"55%"},attrs:{"percentage":_vm.progressPercent,"stroke-width":5}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }